var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{ref:"modalEditarTurno",attrs:{"titulo":"Crear Turno","icon":"ubicación","no-aceptar":"","adicional":"Crear"},on:{"adicional":_vm.crearTurno}},[_c('ValidationObserver',{ref:"validacion"},[_c('div',{staticClass:"row mx-0 justify-center pt-3"},[_c('div',{staticClass:"col-10 mb-4"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"día"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"pl-3 text-general f-14"},[_vm._v("Día del turno")]),_c('el-date-picker',{staticClass:"w-100",attrs:{"type":"date","placeholder":"Fecha","value-format":"yyyy-MM-dd","value":"yyyy-MM-dd","clearable":false,"picker-options":_vm.pickerOptions},model:{value:(_vm.model.fecha_dia),callback:function ($$v) {_vm.$set(_vm.model, "fecha_dia", $$v)},expression:"model.fecha_dia"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"col-10 mb-4"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"horario"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"pl-3 text-general f-14"},[_vm._v("Tiempo del turno")]),_c('el-time-picker',{attrs:{"is-range":"","value-format":"HH:mm","format":"HH:mm","range-separator":"a","start-placeholder":"Hora inicio","end-placeholder":"Hora fin"},model:{value:(_vm.model.horas),callback:function ($$v) {_vm.$set(_vm.model, "horas", $$v)},expression:"model.horas"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"col-10 mb-4"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"cedis"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"pl-3 text-general f-14"},[_vm._v("CEDIS del servicio")]),_c('el-select',{staticClass:"w-100",model:{value:(_vm.model.id_cedis),callback:function ($$v) {_vm.$set(_vm.model, "id_cedis", $$v)},expression:"model.id_cedis"}},_vm._l((_vm.selectCedis),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.nombre,"value":item.id}})}),1),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"col-10 mb-4"},[_c('ValidationProvider',{attrs:{"rules":"required|max:30","name":"vehículo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"pl-3 text-general f-14"},[_vm._v("Vehículo estimado para el turno")]),_c('el-input',{staticClass:"w-100",attrs:{"placeholder":"Vehículo"},model:{value:(_vm.model.vehiculo),callback:function ($$v) {_vm.$set(_vm.model, "vehiculo", $$v)},expression:"model.vehiculo"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"col-10 mb-4"},[_c('ValidationProvider',{attrs:{"rules":"required|max:500","name":"observaciones"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"pl-3 text-general f-14"},[_vm._v("Observaciones")]),_c('el-input',{staticClass:"w-100",attrs:{"type":"textarea","rows":5,"placeholder":"Vehículo"},model:{value:(_vm.model.observaciones),callback:function ($$v) {_vm.$set(_vm.model, "observaciones", $$v)},expression:"model.observaciones"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }