<template>
    <modal ref="modalEditarTurno" titulo="Crear Turno" icon="ubicación" no-aceptar adicional="Crear" @adicional="crearTurno">
        <ValidationObserver ref="validacion">
            <div class="row mx-0 justify-center pt-3">
                <div class="col-10 mb-4">
                    <ValidationProvider v-slot="{errors}" rules="required" name="día">
                        <p class="pl-3 text-general f-14">Día del turno</p>
                        <el-date-picker
                        v-model="model.fecha_dia"
                        type="date"
                        class="w-100"
                        placeholder="Fecha"
                        value-format="yyyy-MM-dd"
                        value="yyyy-MM-dd"
                        :clearable="false"
                        :picker-options="pickerOptions"
                        />
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
                <div class="col-10 mb-4">
                    <ValidationProvider v-slot="{errors}" rules="required" name="horario">
                        <p class="pl-3 text-general f-14">Tiempo del turno</p>
                        <el-time-picker
                        v-model="model.horas"
                        is-range
                        value-format="HH:mm"
                        format="HH:mm"
                        range-separator="a"
                        start-placeholder="Hora inicio"
                        end-placeholder="Hora fin"
                        />
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
                <div class="col-10 mb-4">
                    <ValidationProvider v-slot="{errors}" rules="required" name="cedis">
                        <p class="pl-3 text-general f-14">CEDIS del servicio</p>
                        <el-select v-model="model.id_cedis" class="w-100">
                            <el-option
                            v-for="item in selectCedis"
                            :key="item.id"
                            :label="item.nombre"
                            :value="item.id"
                            />
                        </el-select>
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
                <div class="col-10 mb-4">
                    <ValidationProvider v-slot="{errors}" rules="required|max:30" name="vehículo">
                        <p class="pl-3 text-general f-14">Vehículo estimado para el turno</p>
                        <el-input v-model="model.vehiculo" placeholder="Vehículo" class="w-100" />
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
                <div class="col-10 mb-4">
                    <ValidationProvider v-slot="{errors}" rules="required|max:500" name="observaciones">
                        <p class="pl-3 text-general f-14">Observaciones</p>
                        <el-input v-model="model.observaciones" type="textarea" :rows="5" placeholder="Vehículo" class="w-100" />
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import moment from 'moment';
import Turnos from "~/services/routing/turnos";
export default {
    data(){
        return {
            model: {
                id_cedis: null,
                horas: null,
                fecha_dia: '',
                vehiculo: '',
                observaciones: '',
            },
            pickerOptions: {
                disabledDate(time){
                    return time.getTime() < moment().subtract(1,'days');
                }
            },
        }
    },
    computed: {
        selectCedis(){
            return this.cedis_calculo.filter(o=>o.id != 0)
        }
    },
    methods: {
        toggle(){
            this.$refs.modalEditarTurno.toggle();
        },
        async crearTurno(){
            try {
                const valid = await this.$refs.validacion.validate()
                if(!valid){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return false
                }

                const { data } = await Turnos.crearTurno(this.model)
                this.notificacion('Mensaje', data.message, 'success')
                this.limpiar()
                this.$refs.modalEditarTurno.toggle();
                this.$router.push({name: 'admin.rutas.turno.ver', params: {id_turno: data.model.id}})
            } catch (error){
                this.error_catch(error)
            }
        },
        limpiar(){
            this.model = {
                id_cedis: null,
                horas: null,
                fecha_dia: '',
                vehiculo: '',
                observaciones: '',
            }
            this.$refs.validacion.reset()
        }
    }
}
</script>
